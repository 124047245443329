import * as React from "react"

import Layout from "../components/Layout/layout"
import Seo from "../components/seo"
import { useEffect, useState } from "react"

const NotFoundPage = () => {
  const [isMount, setMount] = useState(false)

  useEffect(() => {
    setMount(true)
  }, [])

  if (!isMount) {
    return (
      <React.Fragment>
        <Seo title="Loading..." />
        <div className="container-fluid text-center">
          <h1>Loading...</h1>
          <p>Please wait page is loading.</p>
        </div>
      </React.Fragment>
    )
  }

  return (
    <Layout>
      <Seo title="404: Not found" />
      <div className="container-fluid text-center">
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
